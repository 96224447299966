import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Box, Typography, Button } from "@mui/material";

import { AccountContext } from "../../../context/AccountContext.js";

import { fetchCustomer } from "../../../lib/StripeManager.js";

import PlanSummary from "./PlanSummary.js";

const IS_BETA = false;

class ManageMembership extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"bt_customer" : null,
		};

		this.cancelSubscription = this.cancelSubscription.bind(this);
		this.refreshBtCustomer = this.refreshBtCustomer.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onMembershipUpdate" : PropTypes.func.isRequired
	};

	componentDidMount() {
		this.refreshBtCustomer();
	}

	/**
	 * TODO:
	 * add cancellation option
	 * ability to update payment method
	 * enable / disable autopay.
	 * membership start date
	 *
	 * Name details for Braintree? or does that go in privacy?
	 */
	render() {
		const { onMembershipUpdate } = this.props;
		let { bt_customer } = this.state;

		return (
			<Box
				sx={{
					"display" : "flex",
					"flexDirection" : "column",
					"alignItems" : "center",
					"width" : "80%"
				}}
			>
				<Typography
					color="font.main"
					variant="h1"
				>
					{
						IS_BETA ?
						"This section is unavailable during beta testing."
						:
						"Thank you for purchasing StratSim Pro"
					}
				</Typography>

				{
					!IS_BETA && bt_customer &&
					<Box sx={{"width" : "100%"}}>
						<PlanSummary
							bt_customer={bt_customer}
							onMembershipUpdate={onMembershipUpdate}
						/>
					</Box>
				}
			</Box>
		);
	}

	cancelSubscription() {
		// change db membership_tier, membership_expiration
		// Cancel bt susbcription, but keep payment method
	}

	refreshBtCustomer() {
		const { setContext } = this.context;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Loading customer info..."
		});

		fetchCustomer((err, bt_customer) => {
			if (err) {
				if (err.response?.status === 401) {
					setContext({
						"snackbar_msg" : "Please add a verified Email Address.",
						"snackbar_sev" : "error",
						"is_loading" : false,
						"loading_msg" : null
					});
				} else {
					log.error(err);
					setContext({
						"snackbar_msg" : "Unable to retrieve customer information.",
						"snackbar_sev" : "error",
						"is_loading" : false,
						"loading_msg" : null
					});
				}
				return;
			}

			this.setState({bt_customer});
			setContext({
				"is_loading" : false,
				"loading_msg" : null
			});
		});
	}
}

export default ManageMembership;
