import React from "react";
// import PropTypes from "prop-types";

import log from "loglevel";

// Material UI imports.
import {
	Button,
	Box,
	TextField,
	Tooltip
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Used for storing relevant strategy data across components.
import { StrategyContext } from "../../context/StrategyContext.js";
import CenterModal from "../CenterModal.js";
import {
	DEFAULT_NEW_TEMPLATE, createGroup, updateTemplate, createStrategy
} from "../../lib/strategy-ops.js";

const default_title = "Untitled Strategy";

class StrategyList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"show_all" : {},
			"visible1" : false,
			"new_group_title" : "",
		};

		this.addNewGroup = this.addNewGroup.bind(this);
		this.addNewStrategy = this.addNewStrategy.bind(this);
		this.renderSimulationTree = this.renderSimulationTree.bind(this);
		this.renderStrategyTree = this.renderStrategyTree.bind(this);
		this.renderGroupTree = this.renderGroupTree.bind(this);
		this.renderContinuousSubmissionTree = this.renderContinuousSubmissionTree.bind(this);
		this.updateNewGroupTitle = this.updateNewGroupTitle.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {};

	componentDidUpdate(prevProps) {
		const {
			clicked_add_strategy, clicked_add_group, selected_leaf, setContext
		} = this.context;

		// Checks if DashboardStart component clicked "Create New Group" or "Create New Strategy"

		if (clicked_add_group === true) {
			setContext({"clicked_add_group" : false}, () => {
				this.setState({"visible1" : true});
			});
		}
		if (clicked_add_strategy === true) {
			setContext({"clicked_add_strategy" : false}, () => {
				this.addNewStrategy(selected_leaf.id);
			});
		}
	}

	render() {
		let {
			groups, selected_node_id,
			expanded_node_ids, membership_tier,
			collapse_list
		} = this.context;
		let { visible1, new_group_title } = this.state;

		console.log({expanded_node_ids, selected_node_id});

		return (
			<Box
				className="strategy-list"
				sx={{
					"backgroundColor" : "background.paper",
					"display" : collapse_list ? "none" : undefined
				}}
			>
				<TreeView
					expanded={expanded_node_ids}
					selected={selected_node_id}
					multiSelect={false} // Only one leaf may be selected at a time.
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					onNodeToggle={(a, b) => {
						this.setState({"show_all" : {}});
					}}
					sx={{"width" : "100%"}}
				>
					{ this.renderContinuousSubmissionTree() }

					<TreeItem
						nodeId={"add_group"}
						label="Add Group"
						sx={{"color" : "font.main"}}
						onClick={() => {
							this.setState({"visible1" : true});
						}} />

					{ groups && this.renderGroupTree(groups) }
				</TreeView>

				<CenterModal
					is_open={visible1}
					title="Create New Group"
					onClose={() => {
						this.setState({
							"visible1" : false,
							"new_group_title" : ""
						});
					}}
				>
						<TextField
							type="text"
							name="new_group_title"
							label="New Group Title"
							value={new_group_title}
							onChange={this.updateNewGroupTitle}
							size="small"
							InputProps={{"sx" : {"typography" : "body2", "color" : "font.main"}}}
							InputLabelProps={{"sx" : {"typography" : "body2"}}}
						/>

						<Box
							className="flex-row-space-between"
							sx={{
								"margin": "40px 0px 0px 0px",
								"width" : "100%"
							}}
						>
							<Tooltip title="Create strategies by hand.">
								<span>
								<Button
									variant="contained"
									onClick={()=>{this.addNewGroup("custom")}}
									sx={{"color":"font.button","marginRight":"10px"}}
								>
									Custom Group
								</Button>
								</span>
							</Tooltip>

							<Tooltip title="Generate strategies automatically.">
								<span>
								<Button
									variant="contained"
									disabled={membership_tier === "demo"}
									onClick={()=>{this.addNewGroup("template")}}
									sx={{"color":"font.button","marginLeft":"10px"}}
								>
									Template Group
								</Button>
								</span>
							</Tooltip>
						</Box>
				</CenterModal>
			</Box>
		);
	}

	updateNewGroupTitle(evt) {
		let s = evt.target.value;
		this.setState({ "new_group_title" : s });
	}

	addNewGroup(group_type) {
		const { setContext, setPage, groups, strategies } = this.context;
		const { new_group_title } = this.state;

		createGroup(new_group_title, group_type, (err, res) => {
			if (err) return log.debug({err});
			this.setState({"visible1" : false, "new_group_title" : ""});

			const new_group = {
				"id" : res.group_id,
				"type" : group_type,
				"title" : new_group_title,
				"template" : group_type === "template" ? DEFAULT_NEW_TEMPLATE : null
			};
			const k = `group-${res.group_id}`;

			setContext({
				"groups" : [
					...groups,
					new_group
				],
				"strategies" : {
					...strategies,
					[k] : []
				}
			});

			if (group_type === "template") {
				updateTemplate(res.group_id, DEFAULT_NEW_TEMPLATE, (err, res) => {
					if (err) return log.error(err);

					setPage(`/dashboard/${k}`);
				});
			} else {
				setPage(`/dashboard/${k}`);
			}

		});
	}

	/**
	 * Creates new strategy and adds to list.
	 */
	addNewStrategy(group_id) {
		let { strategies, setContext, setPage } = this.context;

		createStrategy(group_id, (err, new_strategy) => {
			if (err) return log.debug(err);

			// TODO: Magic number code smell.
			const k = `group-${group_id}`;
			strategies[k].push(new_strategy);
			setContext({ strategies }, () => {
				setPage(`/dashboard/${k}/strategy-${new_strategy.id}`);
			});
		});
	}

	renderGroupTree(groups) {
		const {
			selected_leaf, selected_branch, strategies, setPage, setContext
		} = this.context;

		return groups.map(g => {
			const gk = `group-${g.id}`;

			return (
				<TreeItem
					key={gk}
					nodeId={gk}
					label={g.title}
					sx={{"color" : "font.main"}}
					onClick={(e) => {
						e.preventDefault();
						if (selected_leaf?.id === g.id && selected_branch === "group") {
							setContext({
								"selected_branch" : null,
								"selected_leaf" : null,
								"selected_node_id" : "",
								"expanded_node_ids" : []
							}, () => {
								setPage(`/dashboard`);
							});
							return;
						}

						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading ${gk}...`
						}, () => {
							/// ooooooooff anything more elegant than this?
							// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });
							setTimeout(() => {
								setPage(`/dashboard/${gk}`);
							}, 250);
						});
					}}
				>
					{
						strategies && strategies[gk] &&
						this.renderStrategyTree(strategies[gk])
					}

					{
						g.type !== "template" &&
						<TreeItem
							nodeId={"add_strategy"}
							label="Add Strategy"
							onClick={() => {this.addNewStrategy(g.id)}}/>
					}
				</TreeItem>
			);
		});
	}

	renderStrategyTree(strats) {
		const { show_all } = this.state;
		const {
			selected_leaf, simulations, setPage, setContext
		} = this.context;

		return strats.map(s => {
			// const is_selected = selected_strategy && selected_strategy.id === s.id;
			let current_simulations = simulations[`strategy-${s.id}`];
			if (current_simulations && !show_all[`strategy-${s.id}`]) {
				current_simulations = current_simulations.slice(0, 10);
			}

			/*const label_text = (current_simulations && !show_all[`strategy-${s.id}`]) ?
						`Show All (${simulations[`strategy-${s.id}`].length})`
						:
						"Hide Extra";*/

			return (
				<TreeItem
					key={`strategy-${s.id}`}
					nodeId={`strategy-${s.id}`}
					label={s.title === default_title ? `strategy-${s.id}` : s.title }
					sx={{"color" : "font.main"}}
					onClick={(e) => {
						e.preventDefault();
						if (selected_leaf?.id === s.id && selected_leaf?.group_id === s.group_id) {
							setContext({
								"is_loading" : true,
								"loading_msg" : `Loading group-${s.group_id}...`
							}, () => {
								/// ooooooooff anything more elegant than this?
								// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });
								setTimeout(() => {
									setPage(`/dashboard/group-${s.group_id}`);
								}, 250);
							});
							return;
						}

						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading strategy-${s.id}...`
						}, () => {
							/// ooooooooff anything more elegant than this?
							// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });

							setTimeout(() => {
								setPage(`/dashboard/group-${s.group_id}/strategy-${s.id}`);
							}, 250);
						});
					}}
				>
					{this.renderSimulationTree(current_simulations, s.group_id)}
				</TreeItem>
			);
		});
	}

	renderSimulationTree(sims, group_id) {
		const { setPage, setContext } = this.context;

		if (!sims) return <div />

		return sims.map(cs => {
			const label_node = (<div>simulation-{cs.id}</div>);

			return (
				<TreeItem
					key={`simulation-${cs.id}`}
					nodeId={`simulation-${cs.id}`}
					sx={{"color" : "font.main"}}
					label={label_node}
					onClick={(e) => {
						e.preventDefault();
						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading simulation-${cs.id}...`
						}, () => {
							/// ooooooooff anything more elegant than this?
							// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });
							setTimeout(() => {
								setPage(`/dashboard/group-${group_id}/strategy-${cs.strategy_id}/simulation-${cs.id}`);
							}, 250);
						});
					}}
				/>
			);
		});
	}

	renderContinuousSubmissionTree() {
		const {
			strategies, selected_node_id, setContext, setPage
		} = this.context;
		const continuous_group_node_id = "continuous_group";

		let arr = strategies["continuous"] || [];
		console.log({arr});

		return (
			<TreeItem
				nodeId={continuous_group_node_id}
				label="All Continuous"
				sx={{"color" : "font.main"}}
				onClick={() => {
					if (selected_node_id === continuous_group_node_id) {
						setContext({
							"selected_branch" : null,
							"selected_leaf" : null,
							"selected_node_id" : "",
							"expanded_node_ids" : []
						}, () => {
							setPage(`/dashboard`);
						});
					} else {
						setContext({
							"selected_branch" : null,
							"selected_leaf" : null,
							"selected_node_id" : continuous_group_node_id,
							"expanded_node_ids" : [continuous_group_node_id]
						});
					}
					console.log("continuous_group clicked.");
				}}
			>
				{
					arr.map(s => {
						const sk = `continuous-strategy-${s.id}`;
						return (

							<TreeItem
								key={sk}
								nodeId={sk}
								label={s.title === default_title ? sk : s.title }
								sx={{"color" : "font.main"}}
								onClick={(e) => {
									e.preventDefault();
	
									setContext({
										"is_loading" : true,
										"loading_msg" : `Loading strategy-${s.id}...`
									}, () => {
										/// ooooooooff anything more elegant than this?
										// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });

										setTimeout(() => {
											setPage(`/dashboard/group-${s.group_id}/strategy-${s.id}`);
										}, 250);
									});
								}}
							/>
						);
					})
				}
			</TreeItem>
		);
	}
}

export default StrategyList;
