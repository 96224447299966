import React from "react";
import PropTypes from "prop-types";

import { STRATEGY_HEADERS } from "../../lib/strategy-ops.js";
import { StrategyContext } from "../../context/StrategyContext.js";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
	Box, Typography, Grid, FormControlLabel, Checkbox,
	IconButton
} from "@mui/material";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';


class StrategyTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"selected_headers" : STRATEGY_HEADERS,
			"new_selected_headers" : STRATEGY_HEADERS,
		};

		this.renderHeaderOptions = this.renderHeaderOptions.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"total_strategies" : PropTypes.number.isRequired,
		"page" : PropTypes.number.isRequired,
		"max_pages" : PropTypes.number.isRequired,
		"strategies" : PropTypes.array.isRequired,
		"onPagination" : PropTypes.func.isRequired
	};

	render() {
		const {
			selected_leaf, setPage 
		} = this.context;
		const { strategies, total_strategies, page, max_pages, onPagination } = this.props;
		const { selected_headers } = this.state;

		let is_pressing_ctrl = false;
		// TODO: I feel like there is a better location for this.
		const ctrl_keycode = 17;
		window.addEventListener("keydown", e => {
			if (!is_pressing_ctrl && e.keyCode === ctrl_keycode) {
				is_pressing_ctrl = true;
			}
		});
		window.addEventListener("keyup", e => {
			if (is_pressing_ctrl && e.keyCode === ctrl_keycode) {
				is_pressing_ctrl = false;
			}
		});

		const pagination_sx = {
			"fontSize" : "3.0em",
			"color": "secondary.main"
		};

		const ttl = selected_leaf["last_refresh_time"] || undefined;

		return (
			<Box
				id="strategy-table"
				className="strategy-table"
				sx={{"marginTop" : "60px"}}
			>
				<Box className="flex-row-space-between">
					<Box className="flex-row-center">
						<Typography
							color="font.main"
							variant="h2"
						>All Strategies ({total_strategies.toLocaleString()})</Typography>
					</Box>
					<Box
						className="flex-row-center"
						sx={{"alignItems" : "center"}}
					>
						{
							ttl &&
							<Typography
								color="font.main"
								variant="body2"
							>Refreshed at {ttl.toLocaleTimeString()}</Typography>
						}
						<IconButton
							component={SkipPreviousIcon}
							sx={pagination_sx}
							disabled={page === 1}
							onClick={() => {
								onPagination(1);
							}}
						/>
						<IconButton
							component={ChevronLeftIcon}
							sx={pagination_sx}
							disabled={page === 1}
							onClick={() => {
								onPagination(page - 1);
							}}
						/>

						<Box>
							<Typography color="font.main">
								{page}
							</Typography>
						</Box>

						<IconButton
							component={ChevronRightIcon}
							sx={pagination_sx}
							disabled={page === max_pages}
							onClick={() => {
								onPagination(page + 1);
							}}
						/>
						<IconButton
							component={SkipNextIcon}
							sx={pagination_sx}
							disabled={page === max_pages}
							onClick={() => {
								onPagination(max_pages);
							}}
						/>
					</Box>
				</Box>

				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{selected_headers.map(sh => {
									return (
										<TableCell
											key={sh["id"]}
											sx={{
												"color" : "font.main",
												"&:hover" : {
													"cursor" : "pointer",
													"backgroundColor" : "rgba(0,170,237,0.08)",
												}
											}}
										>
											<Box className="flex-row-center">
												{sh["label"]}
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>

						<TableBody>
							{/* Iteratively generates a row for each position. */}
							{strategies.map((p, idx) => {
								let gid = selected_leaf["id"];
								let sid = p.id;
								let new_path = "/dashboard";
								new_path += `/group-${gid}`;
								new_path += `/strategy-${sid}`;

								return (
									<TableRow
										className={"position-row"}
										key={p["id"]}
										onClick={() => {
											if (is_pressing_ctrl) {
												is_pressing_ctrl = false;
												window.open(new_path, "_blank");
											} else {
												setPage(new_path);
											}
										}}
									>
										{selected_headers.map(sh => {
											const v = sh.calculate(p);

											return (
												<TableCell
													key={sh["id"]}
													align="center"
													sx={{"color" : "font.main"}}
												>{v}</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		);
	}

	renderHeaderOptions() {
		const { new_selected_headers } = this.state;

		const handleCheck = (po, is_checked) => {
			if (is_checked) {
				this.setState({
					"new_selected_headers" : new_selected_headers.filter(sh => sh.id !== po.id)
				});
			} else {
				this.setState({
					"new_selected_headers" : [ ...new_selected_headers, po ]
				});
			}
		}

		return STRATEGY_HEADERS.map(po => {
			const is_checked = new_selected_headers.map(sh => sh.id).includes(po.id);

			return (
				<Grid
					item
					key={po["id"]}
					xs={4}
					className="simulation-header-label"
				>
					<FormControlLabel
						label={po["label"]}
						control={
							<Checkbox
								checked={is_checked}
								onChange={e => {
									handleCheck(po, is_checked);
								}}
							/>
						}
					/>
				</Grid>
			);
		});
	}

}

export default StrategyTable;
