// const { REACT_APP_ENV } = process.env;

export const palette_light = {
	"mode" : undefined,
	"primary" : {
		"main" : "#00aaed", // current
		// "main" : "#22e4a3", // palette 1
		// "main" : "#204a5b", // palette 2
		// "main" : "#0091ff", // palette 3
		// "main" : "#14835c", // palette 4
	},
	"secondary" : {
		"main" : "#673ab7", // current
		// "main" : "#24253b", // palette 1
		// "main" : "#8e9ead", // palette 2
		// "main" : "#735b78", // palette 3
		// "main" : "#080e30", // palette 4
	},
	"success" : {
		"main" : "#66bb6a",
	},
	"info" : {
		"main" : "#00aaed",
	},
	"warning" : {
		"main" : "#ffa726",
	},
	"error" : {
		"main" : "#ff4d00",
	},
	"background" : {
		"paper" : "#f9f9f9",
		"main" : "#f0f0f0"
	},
	// "text" : {
	// 	"primary" : "#f00",
	// 	"secondary" : "#f00",
	// 	"disabled" : "#f00"
	// },
	// "action" : {
	// 	"active" : "#00f",
	// 	"hover" : "#00f",
	// 	"selected" : "#00f",
	// 	"disabled" : "#00f",
	// 	"disabledBackground" : "#00f"
	// },
	// "divider" : {
	// 	"main" : "#0f0"
	// },
	"font" : {
		"button" : "#ffffff",
		"main" : "#000000"
	},
};

export const palette_dark = {
	"mode" : "dark",
	"primary" : {
		// "main" : "#0000ff",
		"main" : "#00aaed",
	},
	"secondary" : {
		// "main" : "#f00",
		"main" : "#673ab7",
	},
	"success" : {
		"main" : "#66bb6a",
	},
	"info" : {
		"main" : "#00aaed",
	},
	"warning" : {
		"main" : "#ffa726",
	},
	"error" : {
		"main" : "#ff3d00",
	},
	"background" : {
		// "paper" : "#f9f9f9",
		"main" : "#1A2030",
		// "main" : "#f0f0f0"
		"paper" : "#575981"
	},
	// "text" : {
	// 	"primary" : "#f00",
	// 	"secondary" : "#f00",
	// 	"disabled" : "#f00"
	// },
	// "action" : {
	// 	"active" : "#00f",
	// 	"hover" : "#00f",
	// 	"selected" : "#00f",
	// 	"disabled" : "#00f",
	// 	"disabledBackground" : "#00f"
	// },
	// "divider" : {
	// 	"main" : "#0f0"
	// },
	"font" : {
		"button" : "#fff",
		"main" : "#ffffff"
	},
}

export const theme_settings = {
	"palette" : palette_light,
	"breakpoints" : {
		"values" : {
			"xs": 900,
			"sm": 1200,
			"md": 1500,
			"lg": 1800,
			"xl": 2100
		}
	},
	"typography" : {
		"fontFamily" : "Roboto",
		"htmlFontSize" : 16,
		"h1" : {
			"fontSize" : "2.5rem",
		},
		"h2" : {
			"fontSize" : "2.0rem",
		},
		"h3" : {
			"fontSize" : "1.8rem",
		},
		"subtitle1" : {
			"fontSize" : "1.6rem",
		},
		"subtitle2" : {
			"fontSize" : "1.4rem",
		},
		"body1" : {
			"fontSize" : "1.2rem",
		},
		"body2" : {
				"fontSize" : "1.0rem",
		},
		"body3" : {
				"fontFamily" : "Roboto",
				"fontSize" : "0.8rem",
		},
		"body4" : {
				"fontFamily" : "Roboto",
				"fontSize" : "0.4rem",
		},
		"button" : {
			"fontSize" : "0.8rem",
		},
		"h4" : undefined,
		"h5" : undefined,
		"h6" : undefined,
		"overline" : undefined,
	}
}

// export default theme_settings;
