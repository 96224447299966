import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import { Modal, Box, Button, Typography } from "@mui/material";

import StrategyList from "./StrategyList.js";
import StrategyDesigner from "./StrategyDesigner.js";
import GroupView from "./GroupView.js";
import SimulationView from "./simulation-view/SimulationView.js";
import DashboardStart from "./DashboardStart.js";

import {
	StrategyContext,
	defaultStrategyContext
} from "../../context/StrategyContext.js";
import {
	AccountContext, defaultAccountContext
} from "../../context/AccountContext.js";

import { getSingleStrategy, getSingleSimulation } from "../../lib/strategy-ops.js";

import {
	getUserGroups,
	getAllSecurities,
	getIndicatorPool,
	getStrategySimulations,
	pollContinuousSimulations,
	getGroupStrategies
} from "../../lib/strategy-ops.js";

import "../../css/strategy-dashboard.css";

import log from "loglevel";
import async from "async";

import { whoAmI } from "../../lib/account-ops.js";

class StrategyDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"current_page" : null,
			"context_data" : defaultStrategyContext,
			"loading_failed" : false,
			"loading_intervals" : null,
			"loaded_user_data" : false,
			"redirect" : null,
			"finished_mount" : false
		};

		// Used as setContext()
		this.updateStrategyContext = this.updateStrategyContext.bind(this);
		this.updateLeaf = this.updateLeaf.bind(this);
		this.checkLeafUpdates = this.checkLeafUpdates.bind(this);

		// Initial calls to populate context.
		this.getUserGroups = this.getUserGroups.bind(this);
		this.loadUserData = this.loadUserData.bind(this);
		this.isValidUrl = this.isValidUrl.bind(this);
		this.handleLoadingSuccess = this.handleLoadingSuccess.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	};

	componentDidMount() {
		log.debug("StrategyDashboard componentDidMount");

		const mountInterval = setInterval(() => {
			const { setContext } = this.context;
			if (!setContext) return;
			clearInterval(mountInterval);
			setContext({"is_loading" : true, "loading_msg" : "Loading user..."})

			// Checks for accounts updates on mount.
			whoAmI((err, context_data) => {
				if (err) {
					log.error(err);
					setContext({
						"snackbar_msg" : "Failed to get session details.",
						"snackbar_sev" : "error"
					});
					return;
				}
	
				// Determines which account context to use based on whoami.
				const { is_authenticated, membership_expiration } = context_data;
				const is_expired = membership_expiration > 0 && new Date().getTime() > membership_expiration;

				setContext({
					...context_data,
					"is_expired" : is_authenticated && is_expired,
					"is_loading" : false
				}, () => {
					this.setState({"finished_mount" : true});
				});
			});
		}, 200);
	}

	componentDidUpdate(prevProps) {
		log.debug("StrategyDashboard componentDidUpdate");
		const {
			is_loaded, is_loading, setContext,
			is_authenticated, is_expired, email
		} = this.context;
		const { location, history } = this.props;
		const {
			loaded_user_data, redirect, finished_mount
		} = this.state;

		// Ensures that a whoAmI call has been completed before loading the dashboard.
		if (!finished_mount) return;
		else if (!is_authenticated || is_expired || !email) {
			const msg = is_expired ? "Your membership has expired." : !is_authenticated ? "Only members can access the Dashboard." : "Please verify your email address.";
			setContext({
				"snackbar_msg" : msg,
				"snackbar_sev" : "error"
			}, async.apply(history.push, "/account"));
			return;
		}

		// Ensures user data is only loaded once.
		if (is_authenticated && !loaded_user_data && !is_loading) {
			this.loadUserData();
		}

		// Checks if the path has changed to a different leaf.
		const p1 = prevProps?.location?.pathname;
		const p2 = location?.pathname;
		if (p1 !== p2) {
			this.checkLeafUpdates();
		}

		if (redirect) {
			this.setState({ "redirect" : null });
		}
	}

	render() {
		const { account_id } = this.context;
		const { context_data, loaded_user_data, loading_failed, redirect } = this.state;
		const is_ready = account_id && account_id >= 1 && loaded_user_data;

		if (redirect) {
			return <Redirect to={redirect} push />
		}

		return (
			<StrategyContext.Provider value={context_data}>
				<Box className="strategy-dashboard">
					<StrategyList />

					<Box className="strategy-dashboard-body">
					{
						is_ready &&
						<Switch>
							<Route
								exact
								path="/dashboard"
								component={DashboardStart}
							/>
							<Route
								path="/dashboard/group-:group_id/strategy-:strategy_id/simulation-:simulation_id"
								component={SimulationView}
							/>
							<Route
								path="/dashboard/group-:group_id/strategy-:strategy_id"
								component={StrategyDesigner}
							/>
							<Route
								path="/dashboard/group-:group_id"
								component={GroupView}
							/>
						</Switch>
					}
					</Box>

				</Box>

				<Modal open={loading_failed}>
					<Box
						className={"center-overlay-modal"}
						sx={{
							"backgroundColor":"background.paper",
							"borderWidth" : "2px",
							"borderStyle" : "solid",
							"borderColor" : "secondary.main"
						}}
					>
						<Typography
							color="font.main"
							variant="h1"
						>Loading Failed</Typography>
						<Button
							variant="contained"
							sx={{"color":"font.button"}}
							onClick={this.loadUserData}
						>Retry</Button>
					</Box>
				</Modal>
			</StrategyContext.Provider>
		);
	}

	loadUserData() {
		const { setContext } = this.context; 
		const { history } = this.props;

		// TODO: Move this into some sort of "setLoading" function.
		// Reset the loading state and restart loading timer.
		setContext({
			"is_loading" : true,
			"loading_msg" : `Loading groups...`
		}, () => {
			this.setState({
				"loading_failed" : false,
			});
		});

		// Pull required API data.
		async.parallel([
			this.getUserGroups,
			getAllSecurities,
			getIndicatorPool
		], (err, results) => {
			if (err) {
				log.error(err);
				this.setState({"loading_failed" : true});
				history.push("/account");
				setContext({
					"is_loading" : false,
					"loading_msg" : null,
					"snackbar_msg" : "A loading failure has occurred.",
					"snackbar_sev" : "error"
				});
				return;
			}

			// Reset loading state.
			this.updateStrategyContext({
				"security_pool" : results[1],
				"indicator_pool" : results[2],
			}, () => {
				this.setState({"loaded_user_data" : true}, () => {
					setContext({
						"is_loading" : false,
						"loading_msg" : null,
					}, this.checkLeafUpdates);
				});
			});
		});
	}

	/**
	 * Not the cleanest implementation.
	 * First queries for each group for this account.
	 * 	for each group, query all strategies.
	 * 		for each strategy, query all simulations
	 *
	 * Once it detects that the last simulation of the last group has been
	 * read, then it calls the provided callback.
	 */
	getUserGroups(cb) {
		const { setContext } = this.context;
		let did_fail = false; // Used to prevent duplicate failure reports.

		this.setState({"loading_msg" : "Loading Dashboard"});

		// Step 1: Query API for groups under account_id.
		getUserGroups((err1, res1) => {
			if (err1) {
				return cb(err1);
			} else if (res1.groups.length === 0) {
				this.handleLoadingSuccess(res1.groups, {}, cb);
				return;
			}

			let { groups, continuous } = res1;

			// Creates empty arrays with group IDs. This must be done here to account for groups with no strategies.
			const strategies = {};
			groups.forEach(g => { strategies[`group-${g.id}`] = []; });
			strategies["continuous"] = continuous

			const series_fn = (gid, idx, cb) => {
				setContext({
					"loading_msg" : `Loading group ${idx + 1} / ${groups.length}`
				}, () => {
					getGroupStrategies(gid, 1, 10, cb);
				});
			};

			// Step 2: Query API for strategies under each group.
			const group_calls = groups.map((g, idx) => async.apply(series_fn, g.id, idx));
			async.series(group_calls, (err2, res2) => {
				if (did_fail) {
					// Required because the callback is called multiple tiles.
					log.debug("Duplicate failure detected in group_calls.");
					return;
				} else if (err2) {
					did_fail = true;
					return cb(err2, "Failed to load strategy data. Please refresh to try again.");
				}

				// Converts returned strategies into array of function calls.
				const strategy_calls = res2.map(r => {
					return async.apply((r, wcb) => {
						// If this group contains no strategies.
						r = r.strategies
						if (r.length === 0) return wcb();
						console.log({r});

						// Saves strategies under correct group key.
						const gk = `group-${r[0].group_id}`;
						strategies[gk] = r;
						// Create empty arrays for simulations for each strategy in this group.
						// strategies[gk].forEach(s => {simulations[`strategy-${s.id}`] = []});
						return wcb();
					}, r);
				});

				// Iterates over each group's strategies
				async.series(strategy_calls, (err4, res4) => {
					if (did_fail) {
						log.debug("Duplicate failure detected in strategy_calls.");
					} else if (err4) {
						did_fail = true;
						return cb(err4);
					}

					this.handleLoadingSuccess(groups, strategies, cb);
				});
			});
		});
	}

	handleLoadingSuccess(groups, strategies, cb) {
		const { membership_tier } = this.context;

		this.updateStrategyContext({
			groups, strategies,
			"setContext" : this.updateStrategyContext,
			"setPage" : (new_url, cb=null) => {
				const { setContext } = this.context;
				const { context_data } = this.state;

				const is_logout = false;

				// Confirms there are no unsaved changes before re-navigating.
				if (!context_data["unsaved_changes"]) {
					this.setState({
						"redirect" : new_url,
						"context_data" : is_logout ? defaultStrategyContext : context_data
					}, cb);
				} else {
					setContext({
						"snackbar_msg" : "Can't leave with unsaved changes.",
						"snackbar_sev" : "error"
					}, cb);
				}
			},
			membership_tier
		});

		return cb(null);
	}

	/**
	 * Ensures that whatever updates were done to the context are given a new object reference.
	 */
	updateStrategyContext(new_context, cb=null) {
		const { setContext } = this.context;

		// Check for snackbar alerts.
		if (new_context["snackbar_msg"] && new_context["snackbar_sev"]) {
			setContext({
				"snackbar_msg" : new_context["snackbar_msg"],
				"snackbar_sev" : new_context["snackbar_sev"],
			});
			delete new_context["snackbar_msg"];
			delete new_context["snackbar_sev"];
		}

		if ("is_loading" in new_context) {
			setContext({
				"is_loading" : new_context["is_loading"],
				"loading_msg" : new_context["loading_msg"]
			});
			delete new_context["is_loading"];
			delete new_context["loading_msg"];
		}

		if (new_context["simulations"]) {
		}

		const old_context = this.state.context_data;
		const context_data = { ...old_context, ...new_context };
		this.setState({ context_data }, cb);
	}

	// TODO: Add check for if the same leaf is being selected twice.
	updateLeaf(new_leaf, new_branch, cb=null) {
		const { setContext } = this.context;
		let {
			strategies, simulations, selected_leaf, selected_branch,
			expanded_node_ids, sim_page_size 
		} = this.state.context_data;
		const valid_branches = ["group", "strategy", "simulation"];

		if (valid_branches.indexOf(new_branch) === -1) {
			log.error(`Invalid branch name provided: ${new_branch}`);
			// TODO: Error handling.
		}

		const selected = `${new_branch}-${new_leaf["id"]}`;

		const is_toggle = selected_leaf && (selected_leaf.id === new_leaf.id && selected_branch === new_branch);
		const is_currently_expanded = expanded_node_ids.includes(selected);
		let expanded = is_toggle && is_currently_expanded ? [] : [selected];

		// Callback after 'expanded' has been built.
		const done = () => {
			// Updated context and call callback.
			this.updateStrategyContext({
				"selected_leaf" : new_leaf,
				"selected_branch" : new_branch,
				"selected_node_id" : selected,
				"expanded_node_ids" : expanded,
				strategies, simulations
			}, () => {
				setContext({
					"is_loading" : false,
					"loading_msg" : null
				}, () => {
					this.setState({
						"loading_failed" : false,
					}, cb);
				});
			});
		};

		this.setState({
			"loading_failed" : false,
		}, () => {
				// Performs various actions based on branch, then call done().
				if (new_branch === "group") {
					// Nothing to do here?
					done();
				} if (new_branch === "strategy") {
					// Expand the parent group.
					const gid = new_leaf["group_id"];
					const gk = `group-${gid}`;
					expanded = [gk, `strategy-${new_leaf.id}`];

					// Expand the tree so the user isn't waiting for the API calls to complete.
					this.updateStrategyContext({
						"selected_node_id" : selected,
						"expanded_node_ids" : expanded,
					});

					/**
					 * Conditionally, call done() if the selected leaf has previously been downloaded.
					 * This allows the remaining code to continue (ie, still refreshes the context with an API call)
					 * however, allows much faster navigation since there is a state there already.
					 * maybe add a loading symbol on the navbar to indicate this?
					 */
					const already_exists = !!simulations[`strategy-${new_leaf["id"]}`];
					if (already_exists) {
						done();
					}

					// Moved from updateStrategyContext
					// Retreives all simulations for this strategy, as well as its queue status.
					// 3 is the id of the default column, Total Return
					getStrategySimulations(new_leaf["id"], 1, sim_page_size, 3, "desc", (err, res) => {
						if (err) {
							log.error(err);
							this.setState({"loading_failed" : true,});
							setContext({
								"is_loading" : true,
								"loading_msg" : "Failed to poll continuous simulations."
							});
							return;
						}

						new_leaf["total_simulations"] = res["total"];
						simulations[`strategy-${new_leaf["id"]}`] = res.simulations;
						if (!new_leaf["is_continuous"]) return done();

						pollContinuousSimulations(new_leaf["id"], (err, res) => {
							if (err) {
								log.error(err);
								this.setState({
									"loading_failed" : true,
									"loading_msg" : "Failed to poll continuous simulations."
								});
								return;
							} else if (res === null) {
								log.debug(`Detected no previous requests for strategy-${new_leaf.id}`);
								return done();
							}

							const { age_ms, submission_age, queue_status } = res;
							new_leaf["age_ms"] = age_ms;
							new_leaf["submission_age"] = submission_age;
							new_leaf["queue_status"] = queue_status;

							return done();
						});

					});
				} else if (new_branch === "simulation") {
					// Expand the parent group.
					const sid = new_leaf["strategy_id"];
					const sk = `strategy-${sid}`;

					const gk = Object.keys(strategies).filter(k => {
						for (let s of strategies[k]) {
							if (s["id"] === sid) return true;
						}

						return false;
					})[0];

					expanded.push(sk);
					expanded.push(gk);
					done();
				} else if (new_branch === "position") {
					// TODO: Stub.
					done();
				}
		});
	}

	isValidUrl(url) {
		const expr1 = /\/dashboard\/group-[0-9]*/;
		const expr2 = /\/dashboard\/group-[0-9]*\/strategy-[0-9]*/;
		const expr3 = /\/dashboard\/group-[0-9]*\/strategy-[0-9]*\/simulation-[0-9]*/;

		if (expr3.test(url)) return 3;
		else if (expr2.test(url)) return 2;
		else if (expr1.test(url)) return 1;
		else return -1;
	}

	checkLeafUpdates() {
		const { is_loading } = this.context;
		const { location, history } = this.props;
		const { context_data } = this.state;
		const {
			selected_leaf, selected_branch, simulations, strategies, groups,
			setContext
		} = context_data;

		const done = (path_leaf, path_branch) => {
			const did_update = !selected_leaf || !selected_branch || path_branch !== selected_branch || path_leaf["id"] !== selected_leaf["id"];
			if (did_update) {
				if (is_loading) {
					this.updateLeaf(path_leaf, path_branch, () => {});
				} else {
					setContext({
						"is_loading" : true,
						"loading_msg" : "Loading page..."
					}, () => {
						this.updateLeaf(path_leaf, path_branch, () => {});
					});
				}
			}
		};

		const url_type = this.isValidUrl(location.pathname);
		const tok = location.pathname.split("/");
		let path_branch = null, matches = [];
		if (url_type === 1) {
			const group_id = parseInt(tok[2].split("-")[1]);
			path_branch = "group";
			matches = groups.filter(g => g["id"] === group_id);
		} else if (url_type === 2) {
			const group_id = parseInt(tok[2].split("-")[1]);
			const strategy_id = parseInt(tok[3].split("-")[1]);
			path_branch = "strategy";
			matches = strategies[`group-${group_id}`].filter(s => s["id"] === strategy_id);
			if (matches.length === 0) {
				getSingleStrategy(strategy_id, (err, res1) => {
					if (err) {
						log.error(err);
						history.push("/dashboard");
						return;
					}

					return done(res1, path_branch);
				});
				return;
			}
		} else if (url_type === 3) {
			const group_id = parseInt(tok[2].split("-")[1]);
			const strategy_id = parseInt(tok[3].split("-")[1]);
			const simulation_id = parseInt(tok[4].split("-")[1]);

			const sk = `strategy-${strategy_id}`;
			path_branch = "simulation";
			matches = simulations[sk] ? simulations[sk].filter(s => s["id"] === simulation_id) : [];

			if (matches.length === 0) {
				getSingleSimulation(simulation_id, (err, res1) => {
					if (err) {
						log.error(err);
						history.push("/dashboard");
						return;
					}

					return done(res1, path_branch);
				});
				return;
			} else if (matches.length !== 1) {
				matches = strategies[`group-${group_id}`].filter(s => s["id"] === strategy_id);
				this.updateLeaf(matches[0], "strategy", () => {
					const new_simulations = this.state.context_data.simulations;
					matches = new_simulations[sk].filter(s => s["id"] === simulation_id);
					if (matches.length !== 1) {
						getSingleSimulation(simulation_id, (err, res) => {
							if (err) {
								log.error("Couldn't find requested page, returning to Dashboard.");
								history.push("/dashboard");
								return;
							}

							return done(res, path_branch);
						});
						return;
					}
					return done(matches[0], path_branch);
				});

				return;
			}

		} else {
			if (location.pathname !== "/dashboard") {
				log.error(`Invalid URL format: ${location.pathname}`);
				history.push("/dashboard");
			}
			return;
		}

		// Couldn't find the leaf, so just go back to the dashboard.
		if (matches.length !== 1) {
			log.error("Couldn't find requested page, returning to Dashboard.");
			history.push("/dashboard");
			return;
		}

		const path_leaf = matches[0];
		return done(path_leaf, path_branch);
	}
}

export default StrategyDashboard;
