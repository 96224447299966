import React from "react";
import PropTypes from "prop-types";

import { Box, Typography, Button, IconButton, Modal, Paper } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import log from "loglevel";

import { AccountContext } from "../../../context/AccountContext.js";
import Checkout from "../Checkout.js";
import CenterModal from "../../CenterModal.js";

import { PRICE_PLANS, turnOffAutopay } from "../../../lib/StripeManager.js";

class PlanSummary extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"confirm_autopay" : false,
			"has_autopay" : false,
			"show_checkout" : false,
			"membership_start_date" : null,
			"plan_id" : null,
		};

		this.handleToggleAutopay = this.handleToggleAutopay.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"bt_customer" : PropTypes.object.isRequired,
		"onMembershipUpdate" : PropTypes.func.isRequired,
	};

	componentDidMount() {
		const { bt_customer } = this.props;

		//if (!bt_customer || !bt_customer.payment_method.is_default) return
		if (!bt_customer) return;

		const { has_autopay, membership_start_date, plan_id } = bt_customer;


		// is autopay
		// start date
		// plan id
		//  override membership_tier to have actual product ID?
		//  then have a map to convert from demo to pro?
		//  right now it can be the same value every time

		this.setState({ has_autopay, membership_start_date, plan_id });
	}

	render() {
		const {
			is_expired, membership_expiration, membership_tier
		} = this.context;
		const { onMembershipUpdate } = this.props;
		const {
			confirm_autopay, has_autopay, show_checkout,
			membership_start_date, plan_id
		} = this.state;

		const has_bt_subscription = !!membership_start_date;
		// const has_paymentmethod = true;
		// const cardNumberHidden = "******4242";

		let membership_period = new Date(membership_expiration).toLocaleDateString();
		if (has_bt_subscription) {
			membership_period = `${new Date(membership_start_date).toLocaleDateString()} - ${membership_period}`;
		}

		const arr = PRICE_PLANS.filter(po => po.plan_id === plan_id);
		let plan_label = arr.length ? (arr[0].label) : ("Generic Pro Membership");

		return (
			<Box
				className="manage-membership-tile"
				component={Paper}
				sx={{"borderColor" : "secondary.main"}}
			>
				<Typography
					color="font.main"
					variant="subtitle1"
					sx={{"textAlign" : "center", "margin" : "0px !important"}}
				>Plan Summary</Typography>

				{
					is_expired &&
					<Box className="flex-row-center">
						<Typography
							color="error.main"
							sx={{
								"textAlign" : "center",
								"fontSize" : "subtitle2.fontSize",
								"marginRight" : "20px"
							}}
						>Your subscription has expired</Typography>

						<Button
							variant="outlined"
							size="small"
							onClick={() => {
								// TODO: Popup modal for dropin?
								this.setState({"show_checkout" : true});
							}}
						>Renew</Button>

					</Box>
				}

				<Box sx={{"display" : "flex", "justifyContent" : "space-between"}}>
					<Typography
						color="font.main"
						variant="body1"
						sx={{"fontWeight" : "bold"}}
					>Plan Name:</Typography>
					<Typography
						color="font.main"
						variant="body1"
						sx={{"textAlign" : "center"}}
					>{plan_label}</Typography>
				</Box>

				<Box sx={{"display" : "flex", "justifyContent" : "space-between"}}>
					<Typography
						color="font.main"
						variant="body1"
						sx={{"fontWeight" : "bold"}}
					>Membership {has_bt_subscription ? "Period" : "Expiration"}:</Typography>
					<Typography
						color={is_expired ? "error.main" : "font.main"}
						variant="body1"
						sx={{"textAlign" : "center"}}
					>{ membership_period }</Typography>
				</Box>

				<Box sx={{"display" : "flex", "justifyContent" : "space-between"}}>
					<Typography
						color="font.main"
						variant="body1"
						sx={{"fontWeight" : "bold"}}
					>Automatic Payments:</Typography>

					<Box className="flex-row-center">
						{
							has_autopay &&
							<IconButton
								sx={{"padding":"0px"}}
								onClick={() => {
									this.setState({"confirm_autopay" : true}, () => {});
								}}
							>
								<EditIcon color="primary" />
							</IconButton>
						}

						<Typography
							color="font.main"
							variant="body1"
							sx={{"textAlign" : "center"}}
						>{ has_autopay ? "Yes" : "No" }</Typography>
					</Box>
				</Box>

				<Box className="flex-row-space-between">
					<Typography
						color="font.main"
						variant="body1"
						sx={{"fontWeight" : "bold"}}
					>Engine Tier:</Typography>

					<Box className="flex-row-center">
						{
							false && has_bt_subscription &&
							<IconButton
								sx={{"padding":"0px"}}
								onClick={() => {}}
							>
								<EditIcon color="primary" />
							</IconButton>
						}

						<Typography
							color="font.main"
							variant="body1"
							sx={{"textAlign" : "center"}}
						>T1</Typography>
					</Box>
				</Box>

				<CenterModal
					is_open={show_checkout}
					onClose={() => {
						this.setState({"show_checkout" : false});
					}}
					title="Checkout"
					sx={{
						"height" : "70vh",
						"overflowY" : "scroll"
					}}
				>
					<Checkout
						onPurchase={err => {
							if (err) log.error(err);
							else onMembershipUpdate();
						}}
					/>
				</CenterModal>

				<Modal open={confirm_autopay}>
					<Box
						className={"center-overlay-modal"}
						sx={{
							"backgroundColor" : "background.paper",
							"borderWidth" : "2px",
							"borderStyle" : "solid",
							"borderColor" : "secondary.main"
						}}
					>
						<Typography
							color="font.main"
							variant="h1"
							sx={{ "margin" : "10px 0px 40px 0px" }}
						>{ has_autopay ? "Disable" : "Enable" } Autopay</Typography>

						<Typography color="font.main" variant="body1">
						{
							has_autopay ?
							`If you disable auto pay, then your member ship will expire on ${new Date(membership_expiration).toLocaleDateString()}`
							:
							`If you enable auto pay, your payment method will be charged to renew your membership on ${new Date(membership_expiration).toLocaleDateString()}`
						}
						</Typography>

						{
							!has_autopay &&
							<Typography color="font.main" variant="body1">
								You can disable this at any time.
							</Typography>
						}

						<Box
							className="flex-row-space-around"
							sx={{ "margin" : "40px 0px 10px 0px" }}
						>
							<Button
								variant="contained"
								sx={{"color":"font.button"}}
								onClick={this.handleToggleAutopay}
							>Confirm</Button>
							<Button
								variant="contained"
								color="warning"
								sx={{"color":"font.main"}}
								onClick={() => {
									this.setState({"confirm_autopay" : false});
								}}
							>Cancel</Button>
						</Box>
					</Box>
				</Modal>
			</Box>
		);
	}

	handleToggleAutopay() {
		const { setContext } = this.context;
		const { has_autopay } = this.state;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Updating autopay settings..."
		});
		this.setState({"confirm_autopay" : false});

		turnOffAutopay((err, res) => {
			if (err) {
				log.error(err);
				return;
			}

			setContext({
				"is_loading" : false,
				"loading_msg" : null
			});
			this.setState({"has_autopay" : !has_autopay});
		});
	}
}

export default PlanSummary;
