import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
	Box, Button, IconButton, MenuItem, Select,
	TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
	Tooltip, Typography, TextField, Grid, Divider 
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/*import log from "loglevel";*/

// Used for storing relevant strategy data across components.
import { StrategyContext } from "../../context/StrategyContext.js";
import DurationInput from "./DurationInput.js";

class StrategyBuilder extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			"accuracy_modal_open" : false,
			"just_updated" : "",
			"selected_parameter" : null,
			"new_parameter_value" : ""
		};

		this.updateRow = this.updateRow.bind(this);
		this.validateParameters = this.validateParameters.bind(this);
		this.removeSignal = this.removeSignal.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"is_dummy" : PropTypes.bool,
		"onChange" : PropTypes.func
	};

	render() {
		const { selected_leaf, indicator_pool, setContext } = this.context;
		const { is_dummy, onChange } = this.props;
		const {
			just_updated, selected_parameter, new_parameter_value
		} = this.state;

		// Maps signals to something usable.
		const entrances = selected_leaf ? selected_leaf.entrance_signals.map(s => {
			return {
				"id" : uuidv4(),
				"title" : s.title,
				"indicator_title" : s.indicator_title,
				"direction" : s.direction,
				"entrance_or_exit" : "N",
				"granularity" : s.granularity,
				"parameters" : s.parameters
			};
		}) : [];
		const exits = selected_leaf ? selected_leaf.exit_signals.map(s => {
			return {
				"id" : uuidv4(),
				"title" : s.title,
				"indicator_title" : s.indicator_title,
				"direction" : s.direction,
				"entrance_or_exit" : "X",
				"granularity" : s.granularity,
				"parameters" : s.parameters
			};
		}) : [];

		const signals = entrances.concat(exits);
		return (
			<Box>
				<Grid
					container
					spacing={0}
					sx={{"justifyContent":"center"}}
				>
					<Grid item xs={3}>
						<Box
							className="flex-col-center"
							sx={{"margin" : "10px"}}
						>
							<Box
								className="flex-row-center"
								sx={{"justifyContent":"flex-start"}}
							>
								<Typography
									color="font.main"
									variant="body3"
									sx={{
										"textAlign" : "left",
										"marginRight":"5px",
									}}
								>Spread Limit</Typography>
								<Tooltip
									title="The maximum allowed spread between bid and ask price for entering a position."
								>
									<InfoOutlinedIcon sx={{"typography":"body3"}}/>
								</Tooltip>
							</Box>
							<TextField
								disabled={is_dummy}
								onChange={onChange}
								name="spread_limit"
								value={selected_leaf.spread_limit}
								type="number"
								size="small"
								sx={{"width":"150px"}}
								InputProps={{"sx" : {"typography" : "body3", "color" : "font.main"}}}
								InputLabelProps={{"sx" : {"typography" : "body3"}}}
							/>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box
							className="flex-col-center"
							sx={{ "margin" : "10px"}}
						>
							<Box
								className="flex-row-center"
								sx={{"justifyContent":"flex-start"}}
							>
								<Typography
									color="font.main"
									variant="body3"
									sx={{"textAlign" : "left", "marginRight":"5px"}}
								>Stop Loss</Typography>
								<Tooltip
									title="The maximum value the price can move against you before automatically exiting."
								>
									<InfoOutlinedIcon sx={{"typography":"body3"}}/>
								</Tooltip>
							</Box>
							<TextField
								disabled={is_dummy}
								onChange={onChange}
								name="stop_loss"
								value={selected_leaf.stop_loss}
								type="number"
								size="small"
								sx={{"width":"150px"}}
								InputProps={{"sx" : {"typography" : "body3", "color" : "font.main"}}}
								InputLabelProps={{"sx" : {"typography" : "body3"}}}
							/>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box
							className="flex-col-center"
							sx={{"margin" : "10px"}}
						>
							<Box
								className="flex-row-center"
								sx={{"justifyContent":"flex-start"}}
							>
								<Typography
									color="font.main"
									variant="body3"
									sx={{"textAlign" : "left", "marginRight":"5px"}}
								>Take Profit</Typography>
								<Tooltip
									title="The maximum price gain before exiting the position."
								>
									<InfoOutlinedIcon sx={{"typography":"body3"}}/>
								</Tooltip>
							</Box>
							<TextField
								disabled={is_dummy}
								onChange={onChange}
								name="take_profit"
								value={selected_leaf.take_profit}
								type="number"
								size="small"
								sx={{"width":"150px"}}
								InputProps={{"sx" : {"typography" : "body3", "color" : "font.main"}}}
								InputLabelProps={{"sx" : {"typography" : "body3"}}}
							/>
						</Box>
					</Grid>
					{/*<Grid item xs={3}>
						<Box sx={{ "margin" : "10px", "display" : "flex", "flexDirection" : "column"}}>
							<Typography
								color="font.main"
								variant="body3"
								sx={{"textAlign" : "left"}}
							>Trailing Stop Loss</Typography>
							<TextField
								disabled={is_dummy}
								onChange={onChange}
								name="trailing_stop_loss"
								value={selected_leaf.trailing_stop_loss}
								type="number"
								size="small"
								InputProps={{"sx" : {"typography" : "body3", "color" : "font.main"}}}
								InputLabelProps={{"sx" : {"typography" : "body3"}}}
							/>
						</Box>
					</Grid>*/}
				</Grid>

				<Divider sx={{"margin" : "20px 0px 20px 0px"}} />

				<TableContainer
					className="strategy-builder flex-col-center"
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Indicator</Typography>
								</TableCell>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Signal</Typography>
								</TableCell>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Parameters</Typography>
								</TableCell>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Direction</Typography>
								</TableCell>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Entrance or Exit</Typography>
								</TableCell>
								<TableCell>
									<Typography
										color="font.main"
										variant="body2"
										align="center"
									>Granularity</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								signals.map((s, idx) => {
									const signal_indicators = indicator_pool && indicator_pool[s.indicator_title] ? indicator_pool[s.indicator_title] : [];

									// const unique_key = `${JSON.stringify({...s, "id": null, "granularity" : null})}-${idx}`;

									if (s && s.parameters) {
										s.parameters.sort((a, b) => {
											return a.signal_index - b.signal_index;
										});
									}

									return (
										<TableRow className="signal_row" key={s.id}>
											{/* Indicator should have searchable dropdown list when selected Material UI Autocomplete Component*/}
											<TableCell>
												<Box className="flex-col-center">
												<Select
													disabled={is_dummy}
													name={"indicator_input"}
													value={s.indicator_title}
													size="small"
													sx={{
														"typography":"body3",
														"color" : "font.main"
													}}
													onChange={(evt) => {
														const { value } = evt.target;
														this.updateRow(signals, s.id, "indicator_title", value);
													}}
												>
													{
														Object.keys(indicator_pool ? indicator_pool : []).map((title, idx) => {
															const { abbv_title } = indicator_pool[title];
															return (
																<MenuItem
																	sx={{
																		"typography":"body3",
																		"color" : "font.main"
																	}}
																	key={`indicator-${idx}`}
																	value={title}
																>{abbv_title}</MenuItem>
															);
														})
													}
												</Select>
												</Box>
											</TableCell>
											{/* Signal should also have a list that is populated based on value of the Indicator choice. Material UI Autocomplete Component */}
											<TableCell>
												<Box className="flex-col-center">
												<Select
													disabled={is_dummy}
													name={"signal_input"}
													value={s.title}
													size="small"
													sx={{
														"typography":"body3",
														"color" : "font.main"
													}}
													onChange={(evt) => {
														const { value } = evt.target;
														this.updateRow(signals, s.id, "title", value);
													}}
												>
													{
														signal_indicators && signal_indicators.signals.map((si, idx) => {
															return (
																<MenuItem
																	sx={{
																		"typography":"body3",
																		"color" : "font.main"
																	}}
																	key={`si-${si.title}-${idx}`}
																	value={si.title}
																>{si.title}</MenuItem>
															);
														})
													}
												</Select>
												</Box>
											</TableCell>
											{/* The format for parameters should be shown based on selected Indicator and Signal. Have tooltip for format? */}
											<TableCell>
												<Box
													sx={{
														"display" : "flex",
														"flexDirection" : "row",
														"justifyContent" : "space-around",
														"alignItems" : "center"
													}}
												>
													{
														s && s.parameters && s.parameters.map((p, pidx) => {
															const k = `${idx}-${pidx}-${p}`;
															const is_editing = selected_parameter === k;
															const help_text = indicator_pool[s.indicator_title].parameters[pidx].title;

															return is_editing ? (
																<Box
																	key={k}
																	sx={{
																		"display" : "flex",
																		"flexDirection" : "row",
																		"alignItems" : "center",
																		"width" : "40%"
																	}}
																>
																	<TextField
																		type="text"
																		variant="standard"
																		autoFocus={true || (just_updated === `${idx}-parameters`)}
																		onChange={e => {
																			const { value } = e.target;
																			const k = `${idx}-parameters`;
																			this.setState({
																				"new_parameter_value" : isNaN(value) ? new_parameter_value : value,
																				"just_updated" : k
																			});
																		}}
																		value={new_parameter_value}
																		size="small"
																		InputProps={{"sx" : {"typography" : "body3", "color" : "font.main"}}}
																		InputLabelProps={{"sx" : {"typography" : "body3"}}}
																		/>
																	<Box
																		sx={{
																			"display" : "flex",
																			"flexDirection" : "column",
																			"justifyContent" : "center",
																			"marginLeft" : "3px"
																		}}
																	>
																		<Tooltip title="Save" placement="top">
																			<IconButton
																				sx={{"padding" : "0px"}}
																				onClick={() => {
																					this.updateRow(signals, s.id, "parameters", `${pidx}-${new_parameter_value}`, () => {
																						this.setState({ "selected_parameter" : null, "new_parameter_value" : ""});
																					});
																				}}
																			>
																				<SaveIcon color="success" fontSize="small"/>
																			</IconButton>
																		</Tooltip>
																		<Tooltip title="Clear" placement="bottom">
																			<IconButton
																				sx={{"padding" : "0px",}}
																				onClick={()=>{this.setState({"selected_parameter":null})}}
																			>
																				<ClearIcon color="error" fontSize="small"/>
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Box>
															) : (
																<Tooltip
																	key={k}
																	title={is_dummy?undefined:help_text}
																>
																	<Typography color="font.main"
																		variant="body3"
																		onClick={()=>{
																			if (!is_dummy) this.setState({"selected_parameter":k})}
																		}
																		sx={{
																			"color" : "font.button",
																			"backgroundColor" : is_dummy ? "#999" : "secondary.main",
																			"borderRadius" : "5px",
																			"margin" : "0px 3px 0px 3px",
																			"padding" : "0px 3px 0px 3px",
																			"&:hover" : {"cursor" : "pointer"}
																		}}
																	>{p}</Typography>
																</Tooltip>
															);
														})
													}
												</Box>
											</TableCell>
											{/* Long or Short (L or S) Material UI Select*/}
											<TableCell>
												<Box className="flex-col-center">
												<Select
													disabled={is_dummy}
													value={s.direction}
													onChange={evt => {this.updateRow(signals, s.id, "direction", evt.target.value)}}
													size="small"
													sx={{
														"typography":"body3",
														"color" : "font.main"
													}}
												>
													<MenuItem
														sx={{
															"typography":"body3",
															"color" : "font.main"
														}}
														value={"L"}
													>Long</MenuItem>
													<MenuItem
														sx={{
															"typography":"body3",
															"color" : "font.main"
														}}
														value={"S"}
													>Short</MenuItem>
												</Select>
												</Box>
											</TableCell>
											{/* Entrance or Exit Material UI Select */}
											<TableCell>
												<Box className="flex-col-center">
												<Select
													disabled={is_dummy}
													value={s.entrance_or_exit}
													onChange={evt => {this.updateRow(signals, s.id, "entrance_or_exit", evt.target.value)}}
													size="small"
													sx={{
														"typography":"body3",
														"color" : "font.main"
													}}
												>
													<MenuItem
														sx={{
															"typography":"body3",
															"color" : "font.main"
														}}
														value={"N"}
													>Entrance</MenuItem>
													<MenuItem
														sx={{
															"typography":"body3",
															"color" : "font.main"
														}}
														value={"X"}
													>Exit</MenuItem>
												</Select>
												</Box>
											</TableCell>
											{/* Any granularity or preset granularities? Maybe split into Unit (day, hour, week) and quantiy? */}
											<TableCell>
												<Box className="flex-col-center">
												<DurationInput
													is_disabled={is_dummy}
													class_name="granularity-input-value"
													auto_focus={just_updated === `${idx}-granularity`}
													value={s.granularity}
													sx={{"width":"60%"}}
													inputProps={{"sx":{"textAlign":"center"}}}
													onChange={(ms) => {
														const min_gran = 1000 * 60 * 10;
														const r = ms % min_gran;

														if (r !== 0) {
															ms -= r;
															setContext({
																"snackbar_sev" : "warning",
																"snackbar_msg" : "Please enter groups of 10 minutes."
															});
														}

														this.updateRow(signals, s.id, "granularity", ms, () => {
															const k = `${idx}-granularity`;

															this.setState({"just_updated" : k});
														});
													}} />
												</Box>
											</TableCell>
											{
												!is_dummy &&
												<TableCell>
													<Tooltip title="Remove Signal">
														<IconButton onClick={() => {this.removeSignal(signals, s.id)}}>
															<RemoveCircleOutlineIcon color="error" />
														</IconButton>
													</Tooltip>
												{/*<Button variant="contained" onClick={() => {this.testAccuracy(s)}}>Test Accuracy</Button>*/}
												</TableCell>
											}
										</TableRow>
									);
								})
							}
						</TableBody>
					</Table>
					<Button
						disabled={is_dummy}
						variant="contained"
						sx={{
							"color" : "font.button",
							"width" : "fit-content",
							"margin" : "20px 0px 20px 0px"
						}}
						onClick={() => {
							selected_leaf.entrance_signals.push({
								"id" : uuidv4(),
								"title" : "MACD Cross Above",
								"indicator_title" : "Moving Average Convergence Divergence",
								"direction" : "L",
								"entrance_or_exit" : "N",
								"granularity" : 14400000,
								"parameters" : [12, 26, 9]
							});

							setContext({ selected_leaf });
						}}
					>Add Signal</Button>
				</TableContainer>
			</Box>
		);
	}

	updateRow(old_signals, row_id, column, new_value, cb1=undefined) {
		let signals = [...old_signals];
		const { indicator_pool } = this.context;
		const { onChange } = this.props;

		// Allows caller to not pass a callback function.
		const cb = cb1 ? cb1 : () => {};

		for (let s of signals) {
			if (s.id === row_id) {
				// TODO: Should probably do some validation at this step.

				switch (column) {
					case "indicator_title":
						s[column] = new_value;

						// Picks a default signal if the indicator is changed. (Prevents invalid signals being set for indicators.)
						let valid_signals = indicator_pool[s.indicator_title].signals;
						if (!valid_signals.filter(sig => sig.title === s.title).length) {
							s.title = valid_signals.length ? valid_signals[0].title : "";
							s.parameters = indicator_pool[s.indicator_title].parameters.map(p => p["default"]);
							return onChange({
								"target" : {
									"name" : "signals",
									"value" : signals
								}
							}, cb);
						}
						break;
					case "parameters":
						/**
						 * TODO:
						 * Detect comma
						 * 	on comma, clear text
						 * 	take new value, add to s.parameters
						 * 	then run new value through validateParamters
						 * change inputs to validateParameters
						 * new_value needs to be constructed from this.parameters, PLUS the new_value. Needs to account for index as well.
						 *
						 * s.parameters needs to then be constructed correctly.
						 *
						 * need logic for exiting is_editing mode.
						 */

						// const old_value = s[column];
						// s[column] = new_value;

						const h_idx = new_value.indexOf("-"); // This is assumed to succeed. It is always called this way.
						const param_idx = Number(new_value.slice(0, h_idx));
						const new_param = new_value.slice(h_idx + 1, new_value.length);
						let old_params = s.parameters;
						old_params[param_idx] = new_param;

						this.validateParameters(old_params.toString(), s, (is_valid, tokenized_params) => {
							s.invalid_parameters = !is_valid;
							if (is_valid) {
								s.parameters = tokenized_params.map(p => Number(p));
								return onChange({
									"target" : {
										"name" : "signals",
										"value" : signals
									}
								}, cb);
							} else {
								return;
							}
						});
						break;
					default:
						s[column] = new_value;

						return onChange({
							"target" : {
								"name" : "signals",
								"value" : signals
							}
						}, cb);
				}
			}
		}
	}

	/*updateStrategy(signals, cb=undefined) {
		const { selected_leaf, setContext } = this.context;

		selected_leaf["entrance_signals"] = signals.filter(s => s.entrance_or_exit === "N").map(s => {
			return {
				"title" : s.title,
				"indicator_title" : s.indicator_title,
				"direction" : s.direction,
				"granularity" : s.granularity,
				"parameters" : s.parameters
			}
		});

		selected_leaf["exit_signals"] = signals.filter(s => s.entrance_or_exit === "X").map(s => {
			return {
				"title" : s.title,
				"indicator_title" : s.indicator_title,
				"direction" : s.direction,
				"granularity" : s.granularity,
				"parameters" : s.parameters
			}
		})

	//	get rid of id field 	parameters converted to actual array and entrance_or_exit get rid of
		setContext({ selected_leaf }, cb);
	}*/

	removeSignal(signals, signal_id) {
		const { onChange } = this.props;

		let new_signals = signals.filter(s => s.id !== signal_id);
		onChange({
			"target" : {
				"name" : "signals",
				"value" : new_signals
			}
		});
	}
	
	validateParameters(parameter_string, signal, cb) {
		const { indicator_pool } = this.context;

		let params = parameter_string.replace(/\s+/g, '').split(","); // Removes all white space and splits into commas
		let correct_format = true;

		for (let p of params) correct_format = correct_format && /^[-+]?\d+(\.\d+)?$/.test(p);
		// TODO: indicator_pool is missing
		correct_format = correct_format && (params.length === indicator_pool[signal.indicator_title].parameters.length);

		return cb(correct_format, params);
	}
}

export default StrategyBuilder;
