import react from "react"
import PropTypes from "prop-types"

import { Box, Button, Typography, Grid, IconButton } from "@mui/material";

import ShowChartIcon from "@mui/icons-material/ShowChart"
import SpeedIcon from "@mui/icons-material/Speed";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BusinessIcon from "@mui/icons-material/Business";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalculateIcon from "@mui/icons-material/Calculate";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import log from "loglevel";

import "../../css/landing-page.css";

import { getTotalSimulations, POLL_INTERVAL } from "../../lib/strategy-ops.js";

class Banner extends react.Component {
	constructor(props) {
		super(props);

		this.state = {
			"viewport_width" : null,
			"selected_image" : 0,
			"images" : [],
			"retrace_focus" : false,
			"fetching_total" : false,
			"backtests_performed" : 0,
			/*"poll_interval" : null*/
		};

		this.handleNextImage = this.handleNextImage.bind(this);
		this.fetchTotal = this.fetchTotal.bind(this);
	}

	static propTypes = {
		"onBtn1" : PropTypes.func,
		"onBtn2" : PropTypes.func.isRequired
	};

	componentDidMount() {
		/* Disabled polling for now.
		this.setState({
			"poll_interval" : setInterval(this.fetchTotal, POLL_INTERVAL)
		}, this.fetchTotal);
		*/

		this.fetchTotal();
	}

	componentWillUnmount() {
		/* Disabled polling for now.
		const { poll_interval } = this.state;
		clearInterval(poll_interval);
		*/
	}

	/**
	 * Sets correct image for banner, based on viewport width.
	 */
	componentDidUpdate() {
		const { viewport_width } = this.state;
		const vw = window.innerWidth;

		// Do nothing if the width hasn't changed.
		if (viewport_width === vw) return;

		let img_width = -1;

		if (vw > 2100) {
			img_width = 2560;
		} else if (vw > 1800) {
			img_width = 1920;
		} else if (vw > 1500) {
			img_width = 1536;
		} else if (vw > 1200) {
			img_width = 1280;
		} else {
			img_width = 1024;
		}

		/* These map to the /public directory. */
		let jumbo_image_1 = `/img/width-${img_width}/jumbotron-1.png`;
		let jumbo_image_2 = `/img/width-${img_width}/jumbotron-2.png`;
		let jumbo_image_3 = `/img/width-${img_width}/jumbotron-3.png`;
		let jumbo_image_4 = `/img/width-${img_width}/jumbotron-4.png`;
		let jumbo_image_5 = `/img/width-${img_width}/jumbotron-5.png`;
		let jumbo_image_6 = `/img/width-${img_width}/jumbotron-6.png`;

		this.setState({
			"viewport_width" : vw,
			"images" : [
				jumbo_image_1, jumbo_image_2, jumbo_image_3,
				jumbo_image_4, jumbo_image_5, jumbo_image_6
			]
		});
	}

	render() {
		const { onBtn1, onBtn2 } = this.props;
		const { selected_image, images, backtests_performed, retract_focus } = this.state;

		let backtest_count = backtests_performed.toLocaleString();
		backtest_count = backtest_count.replaceAll(".00", "");

		return (
			<Box
				className="banner"
				sx={{
					"borderBottomWidth" : "1px",
					"borderBottomStyle" : "solid",
					"borderBottomColor" : "secondary.main"
				}}
			>
				<img
					className="banner-img"
					alt="Designer and Performance"
					src={images[selected_image]}
				/>

				<Box
					className={"banner-img-next"}
					sx={{
						"borderWidth" : "1px",
						"borderStyle" : "solid",
						"borderColor" : "secondary.main",
						"backgroundColor" : "background.main"
					}}
				>
					<IconButton
						className="banner-img-next-btn"
						sx={{
							"backgroundColor" : "secondary.main",
						}}
						onClick={this.handleNextImage}
					>
						<ChevronLeftIcon
							sx={{
								"color" : "font.button",
								"&:hover" : {"color" : "secondary.main"}
							}}
						/>
					</IconButton>
				</Box>


				{
					retract_focus ?
					(
						<Box
							className="banner-focus-cont-retracted"
							sx={{
								"borderColor" : "secondary.main",
								"backgroundColor" : "background.main"
							}}
						>
							<IconButton
								className="retract-focus"
								sx={{
									"backgroundColor" : "secondary.main",
									"&:hover" : { "color" : "secondary.main" }
								}}
								onClick={() => {
									this.setState({"retract_focus" : false});
								}}
							>
								<ChevronLeftIcon
									sx={{
										"color" : "font.button",
										"&:hover" : {"color" : "secondary.main"}
									}}
								/>
							</IconButton>
						</Box>
					)
					:
					(
						<Box
							className="banner-focus-cont"
							sx={{
								"borderColor" : "secondary.main",
								"backgroundColor" : "background.paper"
							}}
						>
							<IconButton
								className="retract-focus"
								sx={{
									"backgroundColor" : "secondary.main",
								}}
								onClick={() => {
									this.setState({"retract_focus" : true});
								}}
							>
								<ChevronRightIcon
									sx={{
										"color" : "font.button",
										"&:hover" : {"color" : "secondary.main"}
									}}
								/>
							</IconButton>

							<Typography color="font.main" variant="h1">Welcome to StratSim</Typography>
							<Typography color="font.main" variant="subtitle1">Take the guess work out of Technical Analysis</Typography>

							<Grid container spacing={4}>
								<Grid item xs={6}>
									<ShowChartIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">8 Performance Metrics</Typography>
								</Grid>
								<Grid item xs={6}>
									<SpeedIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">6 Technical Indicators Supported</Typography>
								</Grid>
								<Grid item xs={6}>
									<CurrencyExchangeIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">18 FX Pairs</Typography>
								</Grid>
								<Grid item xs={6}>
									<BusinessIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">30 US Stocks</Typography>
								</Grid>
								<Grid item xs={6}>
									<AccessTimeIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">Data Updated Every 15 Minutes</Typography>
								</Grid>
								<Grid item xs={6}>
									<CalculateIcon fontSize={"large"} color={"primary"} className={"banner-focus-icon"} />
									<Typography color="font.main" variant="body1">{backtest_count} Backtests Performed</Typography>
								</Grid>
							</Grid>

							<Box className={"banner-btn-cont"}>
								<Button
									disabled={!onBtn1}
									onClick={onBtn1}
									variant="contained"
									color="primary"
									sx={{
										"typography" : "button",
										"color" : "font.button"
									}}
									>Try it Out</Button>

								<Button
									onClick={onBtn2}
									variant="contained"
									color="secondary"
									sx={{
										"typography" : "button",
										"color" : "font.button"
									}}
								>Learn more</Button>
							</Box>
						</Box>
					)
				}
			</Box>
		);
	}

	handleNextImage() {
		const { selected_image } = this.state;

		const i = selected_image + 1;

		this.setState({ "selected_image" : i < 5 ? i : 0 });
	}

	fetchTotal() {
		if (this.state.fetching_total) return;

		this.setState({"fetching_total" : true});
		getTotalSimulations((err, response) => {
			if (err) log.error(err);

			this.setState({
				"fetching_total" : false,
				"backtests_performed" : err ? 0 : response["total"]
			});
		});
	}
}

export default Banner;
