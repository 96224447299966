import React from "react";
import PropTypes from "prop-types";

import {
	Alert, Box, Container, Button, Divider, Typography, Paper
} from "@mui/material";

import log from "loglevel";

import { defaultAccountContext, AccountContext } from "../../context/AccountContext.js";
import { logOut, deleteAccount, whoAmI } from "../../lib/account-ops.js";

import UpdateEmail from "./UpdateEmail.js";
import UpdateUsername from "./UpdateUsername.js";
import UpdatePassword from "./UpdatePassword.js";
import UpdatePhone from "./UpdatePhone.js";
import MembershipSelection from "./MembershipSelection.js";
import CenterModal from "../CenterModal.js";
import ManageMembership from "./manage-membership/ManageMembership.js";

import "../../css/profile.css";

class Profile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"show_delete" : false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	};

	componentDidMount() {
		const { setContext } = this.context;

		// TODO: Replace this with a call to handleSubmit() ?
		whoAmI((err, context_data) => {
			if (err) {
				// TODO: Show some snackbar alert here?
				return log.debug(err);
			}

			// Determines which account context to use based on whoami.
			const { is_authenticated, membership_expiration } = context_data;
			const new_context = is_authenticated ? context_data : defaultAccountContext;
			const is_expired = membership_expiration > 0 && new Date().getTime() > membership_expiration;
			setContext({
				...new_context,
				"is_loaded" : true,
				"is_expired" : is_authenticated && is_expired
			});
		});
	}

	render() {
		const {
			username, email, phone_number,
			created_at, membership_tier, setContext
		} = this.context;

		const join_date = created_at ? new Date(created_at).toLocaleString() : "N/A";
		const { show_delete } = this.state;

		log.debug(`Detected membership: ${membership_tier}`);

		return (
			<Box className="profile-box flex-col-center">
				{
					membership_tier === "demo" &&
					<Alert
						className="demo-alert"
						severity="info"
						sx={{"typography" : "body2"}}
					>
						You are using the free version of StratSim, with reduced functionality. For full features and usability, consider upgrading.
					</Alert>
				}

				{
					!email &&
					<Alert
						className="demo-alert"
						severity="warning"
						sx={{"typography" : "body2"}}
					>
						An email address is required to use StratSim. Please verify your email address.
					</Alert>
				}

				<Box className="profile-section-cont">
					<Typography color="font.main" variant="subtitle1">Account</Typography>
					<Container component={Paper} className="profile-section">
						<Box className="profile-text-split">
							<Typography color="font.main" variant="body1">Username:</Typography>
							<Typography color="font.main" variant="body1">{username || "N/A"}</Typography>
						</Box>
						<UpdateUsername onSubmit={this.handleSubmit} />
						<Divider className="section-div" orientation="horizontal" />

						<Box className="profile-text-split">
							<Typography color="font.main" variant="body1">Email:</Typography>
							<Typography
								color={!!email ? "font.main" : "error.main"}
								variant="body1"
							>{email || "N/A"}</Typography>
						</Box>
						<UpdateEmail onSubmit={this.handleSubmit} />
						<Divider className="section-div" orientation="horizontal" />

						<Box className="profile-text-split">
							<Typography color="font.main" variant="body1">Join Date:</Typography>
							<Typography color="font.main" variant="body1">{join_date}</Typography>
						</Box>

						<Button
							variant="contained"
							color="warning"
							sx={{"color" : "font.main"}}
							onClick={() => {
								logOut(err => {
									if (err) return log.debug(err);

									setContext(defaultAccountContext, () => {
										window.location.reload(false);
									});
								});
							}}
						>Log Out</Button>
					</Container>
				</Box>


				<Box className="profile-section-cont">
					<Typography color="font.main" variant="subtitle1">Membership</Typography>

					<Container component={Paper} className="profile-section">
						{
							membership_tier !== "demo" ?
								<ManageMembership onMembershipUpdate={this.handleSubmit} />
								:
								<MembershipSelection onMembershipUpdate={this.handleSubmit} />
						}
					</Container>
				</Box>

				<Box className="profile-section-cont">
					<Typography color="font.main" variant="subtitle1">Privacy</Typography>

					<Container component={Paper} className="profile-section">
						<Box className="profile-text-split">
							<Typography color="font.main" variant="body1">Password:</Typography>
							<Typography color="font.main" variant="body1">********</Typography>
						</Box>
						<UpdatePassword onSubmit={this.handleSubmit} />
						<Divider className="section-div" orientation="horizontal" />

						<Box className="profile-text-split">
							<Typography color="font.main" variant="body1">Phone Number:</Typography>
							<Typography color="font.main" variant="body1">{phone_number || "N/A"}</Typography>
						</Box>
						<UpdatePhone onSubmit={this.handleSubmit} />
						<Divider className="section-div" orientation="horizontal" />

						<Button
							onClick={() => {
								this.setState({"show_delete" : true});
							}}
							variant="contained"
							color="error"
							sx={{"color" : "font.button"}}
						>Delete Account</Button>
					</Container>
				</Box>


				<CenterModal
					is_open={show_delete}
					title="Delete Account"
					onClose={() => {
						this.setState({"show_delete" : false});
					}}
				>
					<Typography
						color="font.main"
						variant="subtitle1"
					>Delete your StratSim Account?</Typography>

					<Typography
						color="font.main"
						variant="body1"
					>
						This action is cannot be undone. All of your personal information, strategies, templates, and continuous tests will be deleted.
					</Typography>

					<Button
						variant="contained"
						color="error"
						sx={{
							"margin" : "20px",
							"color" : "font.button"
						}}
						onClick={this.handleDeleteAccount}
					>Confirm Delete</Button>
				</CenterModal>
			</Box>
		);
	}

	handleSubmit() {
		const { setContext } = this.context;

		whoAmI((err, context_data) => {
			if (err) {
				// TODO: Show some snackbar alert here?
				return log.debug(err);
			}

			// Determines which account context to use based on whoami.
			const { is_authenticated, membership_expiration } = context_data;
			const new_context = is_authenticated ? context_data : defaultAccountContext;
			const is_expired = membership_expiration > 0 && new Date().getTime() > membership_expiration;
			log.debug({is_expired, membership_expiration, is_authenticated});
			setContext({
				...new_context,
				"is_expired" : is_authenticated && is_expired
			});
		});
	}

	/**
	 * Currently a stub for the account deletion process.
	 */
	handleDeleteAccount() {
		const { setContext } = this.context;

		deleteAccount(err => {
			if (err) {
				log.debug(err);
				return;
			}

			// TODO: This should exit similar to logout?
			setContext(defaultAccountContext, () => {
				window.location.reload(false);
			});
		});
	}
}

export default Profile;
